//import {Get_Set_User, Current_Converse,Chatse,DeL_Chatse} from './constantine'
import {Add_To_Datatable,Clear_Datatable,Add_To_Datatable_Filter,Clear_Datatable_Filter} from './constantine'
import { omit } from 'lodash'

const initialState = {
    url:{ 
        //UerEls: "http://192.168.1.3:3000/",  
         UerEls : "http://192.168.1.3:3000/",  


        routes:{
            hak_e:`api/hak_e`
            // subkategori:`api/subkategori/`,

            // satu_pintu_kecamatan:`api/end_satu_pintu_kecamatan/`,
            // satu_pintu_kelurahan:`api/end_satu_pintu_kelurahan/`,
            // satu_pintu_bulan:`api/end_satu_pintu_bulan/`,
            // satu_pintu_daerah:`api/end_satu_pintu_daerah/`,
            // end_1_4_1:`api/end_1.4.1/`,
            // one_for_all:`api/end_satu_pintu_one_for_all/`,

            // end_download:`api/end_download/`,
            // end_show_download:`api/end_show_download/`,
            // end_visit:`api/end_visit/`,
            // end_show_visit:`api/end_show_visit/`
            //  images_dir:'images_dir/',
            // images_store_dir:'images_store_dir/',
            // images_pro_pic:'images_pro_pic/',

            // list_produk_umum:'list_produk_umum/',
            // list_produk:'list_produk/',
            // list_pdg:'list_pdg/',
            // list_pdg_full:'list_pdg_full',
            // list_pdg_look_for:'list_pdg_look_for/',
            // list_pdg_nearby_limit:'list_pdg_nearby_limit/',
            // list_pdg_nearby_full:'list_pdg_nearby_full/',


            // login_user:'login_user/',
            // regis_user:'regis_user/',

            // insert_totran:'insert_totran/',
            // insert_deta_tran:'insert_deta_tran/',
            // pesanan_aktif_cust:'pesanan_aktif_cust/',
            // pesanan_aktif_cust_fini:'pesanan_aktif_cust_fini/',

            // detail_pesanan:'detail_pesanan/',
            // batal_pesanan:'batal_pesanan/',

            // prodic:'prodic/',
            // prodic_full:'prodic_full/',

            // updt_user:'updt_user/',

            // whois_pemesan:'whois_pemesan',
            // whois_pengantar:'whois_pengantar/',

            // insert_ratings_store:'insert_ratings_store/',
            // insert_ratings_pengantar:'insert_ratings_pengantar/',

            // check_rastore:'check_rastore/',
            // check_rapeng:'check_rapeng/',

            // tell_upcoming:'tell_upcoming/',
            // tell_eachother:'tell_eachother/'

        },
    },

    // datatable:[],
    // datatable_filter:[],
    profile:[],
    //converses:[],

    chatse:[],

    
}

const reducere = (state = initialState, action)=>{
    switch(action.type){
        // case Add_To_Datatable:
        //     return{
        //         ...state,datatable:[...state.datatable,...action.playload]
        //     }
        // case Clear_Datatable:{
        //     return {
        //         ...state,
        //             datatable:[],
        //     }
        // }
        // case Add_To_Datatable_Filter:
        //     return{
        //         ...state,datatable_filter:[...state.datatable_filter,...action.playload]
        //     }
        // case Clear_Datatable_Filter:{
        //     return {
        //         ...state,
        //             datatable_filter:[],
        //     }
        // }    
        
        
        // case Get_Set_User:
        //     return{
        //         ...state,profile:{...state.profile,...action.playload}
        //     }
            
        // case Current_Converse:{
        //     return{
        //             ...state,converses:{...state.converses,...action.playload}
        //     }
        // }  

        // case Chatse:{
        //     const {id_totran} = action.playload[0]
        //     console.log(...state.chatse)
        //         return{
        //             ...state,chatse:[...state.chatse,...action.playload]
        //         }

        // }

        // case DeL_Chatse:{
        //     const {id_totran} = action.playload
        //     //const dels = omit(state.chatse,id_totran)
        //     const derulo = state.chatse.filter((item)=>{
        //         return omit(item.id_totran.match(id_totran))
        //     })
        //     return{
        //         ...state,chatse: derulo 
        //     }

        // }  
        default:
            return state    
    }
};

export default reducere;
