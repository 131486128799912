//import {Get_Set_User,Relocate_Umum, Add_To_Cart,INCREASE_QUANTITY,DECREASE_QUANTITY,Remove_From_Cart,Empty_Cart,Loka,DeLoka, Chatse, DeL_Chatse, Current_Converse} from './constantine'
import {Add_To_Datatable,Clear_Datatable,Add_To_Datatable_Filter,Clear_Datatable_Filter, Server_Time} from './constantine'
export const add_to_datatable = data =>({
        type:Add_To_Datatable,
        playload:data
})
export function clear_datatable(asu){
        return {
                type:Clear_Datatable,
                playload:asu
        }
}
export const add_to_datatable_filter = data =>({
        type:Add_To_Datatable_Filter,
        playload:data
})
export function clear_datatable_filter(asu){
        return {
                type:Clear_Datatable_Filter,
                playload:asu
        }
}

export const server_time = data =>({
        type:Server_Time,
        playload:data
})
// export const Set_Get_Sess = user =>({
//         type:Get_Set_User,
//         playload:user
// })

// export const Produk_Umum = prdk =>({
//         type:Relocate_Umum,
//         playload:prdk
// })


// export function addToCart(tok,item,qua){
//         return{
//             type:Add_To_Cart,
//             playload:{tok,item,qua}
//         }
//     }
//  // Asline Podo Ae karo DeLoka   
// export function Clear(asu){
//         return {
//                 type:Empty_Cart,
//                 playload:asu
//         }
// }

// export function Plus(item){
//         return{
//                 type:INCREASE_QUANTITY,
//                 playload:item
//         }
// }

// export function Lok(item){
//         return{
//                 type:Loka,
//                 playload:item
//         }
// }

// export function Min(item) {
//         return {
//             type:DECREASE_QUANTITY,
//             playload:item 
            
//         }
// }

// export function DeLok(){
//         return{
//                 type:DeLoka
//         }
// }


// export const chatSe = deta =>{
//         return{
//                 type:Chatse,
//                 playload:deta
//         }
// }

// export const del_Chatse = dtz =>{
//         return{
//                 type:DeL_Chatse,
//                 playload:dtz
//         }
// }

// export const current_cons = dts =>{
//         return{
//                 type:Current_Converse,
//                 playload:dts
//         }
// }