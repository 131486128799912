
import {connect} from 'react-redux';

//import {Set_Get_Sess,Produk_Umum,addToCart,Clear,Plus,Min, Lok, DeLok, chatSe, del_Chatse} from './rdx/action'
import {add_to_datatable,clear_datatable,add_to_datatable_filter,clear_datatable_filter,server_time} from './rdx/action'
//import BackgroundTask from 'react-native-background-task'

const mapStateToProps = state => {
    return {
        reds : {...state.reds},
        prod : {...state.prod},
        crat : {...state.crat}

    }
  }
  
  const mapDispactToProps = dispatch => {
    return {
        add_to_datatable: data => dispatch(add_to_datatable(data)),
        clear_datatable: () => dispatch(clear_datatable()),
        add_to_datatable_filter: data => dispatch(add_to_datatable_filter(data)),
        clear_datatable_filter: () => dispatch(clear_datatable_filter()),

        server_time: data => dispatch(server_time(data))
        // Set_Get_Sess : data => dispatch(Set_Get_Sess(data)),
        // Produk_Umum : data => dispatch(Produk_Umum(data)),
        // addToCart:(data,data1,data2) => dispatch(addToCart(data,data1,data2)),
        // Clear:()=>dispatch(Clear()),
        // Plus: data => dispatch(Plus(data)),
        // Min: data => dispatch(Min(data)),
        // Lok: data => dispatch(Lok(data)),
        // DeLok:()=>dispatch(DeLok()),
        // chatSe: data => dispatch(chatSe(data)),
        // del_Chatse: data => dispatch(del_Chatse(data)),
        //current_cons: data => dispatch(current_cons(data))

  
    }
  }


  export const Fuck =(Compotte)=>{
    return(
     connect(mapStateToProps,mapDispactToProps)(Compotte)
    )
  }
