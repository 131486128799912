import logo from './logo.svg';
import './App.css';
import React from 'react';
import * as ReRou from 'react-router-dom'
import Home from './Home/Home'
import Login_lv_0 from './Login_lv_0/Login_lv_0'
import Login_lv_1 from './Login_lv_1/Login_lv_1'
import {Fuck} from './allseeing'

class App extends React.Component {
  render(){
      return (
        <div className="App">
          <ReRou.BrowserRouter>
                <ReRou.Routes>
                  <ReRou.Route path="/" element={<Login_lv_0 />} />
                  <ReRou.Route path="/Home" element={<Home />} />
                  <ReRou.Route path="/Login" element={<Login_lv_0 />} />
                  <ReRou.Route path='/Login_1' element={<Login_lv_1/>} />
                  
                </ReRou.Routes>
          </ReRou.BrowserRouter>
        </div>
      )
  }
}

let ppA = App;
export default ppA;
