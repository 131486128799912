import React, { useEffect } from 'react';
import loadable from '@loadable/component'
import {Fuck} from '../allseeing'
import axios from 'axios'
import { Navigate , Link, Route , useNavigate , useLocation} from 'react-router-dom'
//import $ from "jquery";

const Atas = loadable(()=> import('../Ndas/Atas_Login'))
const Bawah = loadable(()=> import('../Sikil/Bawah_Login')) 

function Login_lv_1 (props) {
    const navigate = useNavigate();
    const location = useLocation();
    const [ Isi_Sila, setIsi_Sila] = React.useState('')
    const [Sila, setSila] = React.useState(0)

    useEffect(()=>{
        //console.log(location.state)
        random_sila(5,1)
    })
    const login_1=(event)=>{
        event.preventDefault(); 
        axios.post(props.reds.url.UerEls+'api/login_lapis_1',{
        //axios.post('http://192.168.1.66:3000/api/login_lapis_0',{
            sila: Sila,
            isi_sila: Isi_Sila
        },{
            headers:{
                'Access-Control-Allow-Origin': true,
            }
        }).then((response)=>{
                if(response.data.lanjut == 'ok'){
                    navigate('/Home')
                }
                console.log(response.data.lanjut)
        })

    }

    function random_sila (min,max){
        const asw = Math.floor(Math.random()*(max-min+1)+min)
        console.log(asw)
        return setSila(asw)
    }

   
      
        return(
            <html lang="en">
                <Atas />
                <body>
                <div class="container">

                        <div class="row justify-content-center">

                            <div class="col-xl-10 col-lg-12 col-md-9">

                                <div class="card o-hidden border-0 shadow-lg my-5">
                                    <div class="card-body p-0">
                                        <div class="row">
                                            <div class="col-lg-6 d-none d-lg-block bg-login-image"></div>
                                            <div class="col-lg-6">
                                                <div class="p-5">
                                                    <div class="text-center">
                                                        <h1 class="h4 text-gray-900 mb-4">Isi dari Sila ke {Sila}</h1>
                                                    </div>
                                                    <form class="user"  onSubmit={login_1.bind(this)}>
                                                        <div class="form-group">
                                                            <input type="text" class="form-control form-control-user"
                                                                id="exampleInputEmail" aria-describedby="emailHelp"
                                                                placeholder="nomor induk pegawhy" onChange={val => setIsi_Sila(val.target.value)} />
                                                        </div>
                                                        {/* <div class="form-group">
                                                            <div class="custom-control custom-checkbox small">
                                                                <input type="checkbox" class="custom-control-input" id="customCheck" />
                                                                <label class="custom-control-label" for="customCheck">Remember
                                                                    Me</label>
                                                            </div>
                                                        </div> */}
                                                        <button class="btn btn-primary btn-user btn-block" >
                                                            Login
                                                        </button>
                                                    </form>
                                                    <div class="text-center">
                                                        <a class="small" href="#">Forgot Password?</a>
                                                    </div>
                                                    <div class="text-center">
                                                        <a class="small" href="/Home">Create an Account!</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                

                            </div>

                        </div>

                        </div>
                    <Bawah />
                </body>
            </html>
        )
    
}
const Logiv_1 = Fuck(Login_lv_1)
export default Logiv_1;