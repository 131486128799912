import {INCREASE_QUANTITY, DECREASE_QUANTITY ,Add_To_Cart,Remove_From_Cart,Empty_Cart,Quan,Relocate_Umum,Loka,DeLoka, Chatse, DeL_Chatse, Current_Converse} from './constantine'
import { omit } from 'lodash'

const initialState1 = {
    cart: [],
    tokso : [],
    loka:[],


//    total: 0,
//    qty: 0,
}
const cartReducer = (state = initialState1, action)=> {
    switch(action.type){
        // case Quan:
        //    return{
        //     ...state,
        //     qty: state.qty,...action.playload
        //    };

        // case Add_To_Cart:{
               

        //         let { id_produk, hargane , nama_produk,url_produk } = action.playload.item
        //         let { qua } = action.playload
        //         let { id_store,nama_store,lat,lng } = action.playload.tok
    
        //                 // IF ITEM IS ALREADY IN THE CART ADD TO QUANTITY
        //                 if (state.cart[id_produk]) {
        //                     return {
        //                         ...state,
        //                         cart: {
        //                         ...state.cart,
        //                             [id_produk]: {
        //                                 id_produk,
        //                                 qua: state.cart[id_produk].qua + qua,
        //                                 nama_produk,
        //                                 url_produk,
        //                                 hargane,
        //                                 topri: (state.cart[id_produk].qua + qua)  * hargane,
        //                                 }
        //                         }
        //                     }
              
        //                 }else{
        //                     return {
        //                         ...state,
        //                         cart: {
        //                         ...state.cart,
        //                                 [id_produk]: {
        //                                     id_produk,
        //                                     nama_produk,
        //                                     url_produk,
        //                                     qua,
        //                                     hargane,
        //                                     topri: hargane,
        //                                 }
                                    
        //                         },
        //                         tokso: action.playload.tok
        //                     }
        //             }
                    
            
        // }
        
        // case Empty_Cart:{
        //     return {
        //         ...state,
        //             cart:[],
        //             tokso:[]
        //     }
        // }

        //     case Remove_From_Cart:{
               
        //         let { id_produk } = action.playload.item;
        //         const updateRemoved = omit(state.cart,id_produk)
    
        //         return {
        //             ...state,cart: updateRemoved
        //         }
        //     }
        //     case INCREASE_QUANTITY: {
        //         let { id_produk, nama_produk , hargane, url_produk } = action.playload;
        //         const {qua} = action.playload
        //         console.log(action.playload)
        //         return {
        //             ...state,
        //             cart: {
        //                 ...state.cart,
        //                 [id_produk]: {
        //                     id_produk,
        //                     nama_produk,
        //                     url_produk,
        //                     qua: state.cart[id_produk].qua + 1,
        //                     hargane,
        //                     topri: (state.cart[id_produk].qua + 1) * hargane
        //                 }
        //             }
        //         }
        //     }
        //     case DECREASE_QUANTITY: {
        //         let { hargane , id_produk , nama_produk,url_produk,qua } = action.playload
        //         console.log(action.playload)
        //         // IF QUANTITY IS 1 REMOVE THE ITEM TO AVOID NEGATIVE INTEGERS
        //         if (state.cart[id_produk] && qua === 1) {
        //             const updateRemoved = omit(state.cart, id_produk)
        //             return {
        //                 ...state,
        //                 cart: updateRemoved
        //             }
        //         }
    
        //         return {
        //             ...state,
        //             cart: {
        //                 ...state.cart,
        //                 [id_produk]: {
        //                     id_produk,
        //                     nama_produk,
        //                     url_produk,
        //                     qua: state.cart[id_produk].qua - 1,
        //                     hargane,
        //                     topri: (state.cart[id_produk].qua - 1) * hargane
        //                 }
        //             }
        //         }
        //     }
        // case Loka:{
        //     return{
        //         ...state,
        //                 loka:[action.playload]
        //     }
        // }
        
        // case DeLoka:{
        //     return{
        //         ...state,
        //                 loka:initialState1
        //     }
        // }

        




        default:
        return state;
    }
}

export default cartReducer;