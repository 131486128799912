import {createStore,combineReducers,compose, applyMiddleware} from 'redux'
import reducere from './reducer';
import cartReducer from './cartReducer';
import prodsReducer from './prodsReducer';
import {persistReducer,persistStore,persistCombineReducers}from 'redux-persist'
//import {AsyncStorage} from 'react-native'
import {AsyncStorage} from 'AsyncStorage';
import thunk from 'redux-thunk'
const midleware = [thunk];
const initialState = {};

let confige = {
    key:'root',
    storage: AsyncStorage ,
    blacklist : ['prod','crat','reds']
}

let asu = combineReducers({
    reds: reducere,
    crat: cartReducer,
    prod: prodsReducer
})

const persistedReducer = persistCombineReducers(confige,{reds:reducere,crat:cartReducer,prod:prodsReducer})

export const store = createStore(persistedReducer,initialState,compose(applyMiddleware(...midleware)));
export const persistor = persistStore(store,null,()=>{store.getState()})
//console.log(store.getState()) 

