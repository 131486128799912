//import {Get_Set_User, Relocate_Umum} from './constantine'
import {Add_To_Datatable,Clear_Datatable,Add_To_Datatable_Filter,Clear_Datatable_Filter,Server_Time} from './constantine'

const initialState3 = {
    prodse:[],
    datatable:[],
    datatable_filter:[],
    time:''
    
}

const prodsReducer = (state = initialState3, action)=>{
    switch(action.type){
        case Add_To_Datatable:
            return{
                ...state,datatable:[...state.datatable,...action.playload]
            }
        case Clear_Datatable:{
            return {
                ...state,
                    datatable:[],
            }
        }
        case Add_To_Datatable_Filter:
            return{
                ...state,datatable_filter:[...state.datatable_filter,...action.playload]
            }
        case Clear_Datatable_Filter:{
            return {
                ...state,
                    datatable_filter:[],
            }
        } 

        case Server_Time:{
            return{
                ...state,time:[...state.time,...action.playload]
            }
        }
        
        // case Relocate_Umum:
        //     return{
        //         ...state,prodse:{...state.prodse,...action.playload}
        //     }
        default:
            return{...state}    
    }
};

export default prodsReducer;
