import React, { useState } from 'react';
import loadable from '@loadable/component'
import {Fuck} from '../allseeing'
import axios from 'axios'
import moment from 'moment'
import 'moment-precise-range-plugin'
import SocketIOClient from 'socket.io-client'
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

const Atas = loadable(()=> import('../Ndas/Atas')) 
const Headers = loadable(()=> import('../Ndas/Headers')) 
const Bawah = loadable(()=> import('../Sikil/Bawah')) 
const Sikil = loadable(()=> import('../Sikil/Sikil')) 
const Side = loadable(()=> import('../Side/Side')) 



class Home extends React.Component {
    constructor(){
        super();
        this.state = {
            datatable :[]
        }
        //this.so();
    }

    componentDidMount(){
        this.update_all_2()
        //this.Conjure_Jquery()
        //this.getHak()
    }

    // so(){
    //     this.socket = SocketIOClient.connect(this.props.reds.url.UerEls,{timeout:10000,transports:['polling','websocket']});
    //     this.socket.on('datetime',resp => {
    //         this.props.server_time(resp)
    //         console.log(resp)
    //     })
    // }

    // differ(umpan){
    //     let end = moment(umpan)
    //     let start = moment()
    
    //     let diff = moment.preciseDiff(end,start, true);
    //     if(start > end){
    //         return (<text style={{fontWeight:'bold'}}>{0+" "+"years"+" "+0+" "+"bulan"+" "+0+" "+"dino"+" "}</text>);
    //     }else{
    //         return (<text style={{fontWeight:'bold'}}>{diff.years+" "+"years"+" "+diff.months+" "+"bulan"+" "+diff.days+" "+"dino"+" "}</text>);
    //     }
    // }
  

    // getHak(){
    //     const url = this.props.reds.url.UerElz+this.props.reds.url.routes.hak_e;
    //     axios.get(url).then((response)=>{
    //             console.log(response.data)
    //             this.props.clear_datatable()
    //             this.props.add_to_datatable(response.data)
    //     })
    // }

    update_all_2=()=>{
        axios.get(this.props.reds.url.UerEls+'api/update_all_2').then((response)=>{
            console.log(response)
        }).then((res)=>{
            this.Conjure_Jquery()
        })
    }

    Conjure_Jquery(){
        $(document).ready(function (ngarep) {
            var link = `http://192.168.110.247:3000/api/post_hak_e_2`
            $('#example').DataTable({
                processing: true,
                serverSide: true,
                serverMethod: 'get',
                ajax : link,
                columns: [
                    { data: 'nomor_hak' },
                    { data: 'tipe_hak' },
                    { data: 'kecamatan' },
                    { data: 'kelurahan' },
                    { data: 'luas_m2' },
                    { data: 'nama_pemegang_hak' },
                    { data: 'tgl_berakhir_hak' },
                    { data : 'to_expire'}
                ],
                columnDefs: [
                        { 
                            "targets": [ 0 ], //first column / numbering column
                            "orderable": false, //set not orderable
                        },
                    ],
                ordering: false    
                
                
                    
            });
        });
    }

    render(){
        const {datatable} = this.props.prod;
        
        return (
        <html lang="en">
            <Atas/>
            <body id="page-top">
                <div id="wrapper">
                    <Side />
                    <div id="content-wrapper" class="d-flex flex-column">
                        <div id="content">
                            <Headers />
                            <div class="container-fluid">

                                {/* <h1 class="h3 mb-2 text-gray-800">Tables</h1>
                                <p class="mb-4">DataTables is a third party plugin that is used to generate the demo table below.
                                    For more information about DataTables, please visit the <a target="_blank"
                                        href="https://datatables.net">official DataTables documentation</a>.</p> */}

                                <div class="card shadow mb-4">
                                    <div class="card-header py-3">
                                        <h6 class="m-0 font-weight-bold text-primary">Hak Atas Tanah</h6>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-responsive">
                                            
                                            <table class="table table-bordered" id="example" width="100%" cellspacing="0">
                                                <thead>
                                                    <tr>
                                                        <th>nomor_hak</th>
                                                        <th>tipe_hak</th>
                                                        <th>kecamatan</th>
                                                        <th>kelurahan</th>
                                                        <th>luas_m2</th>
                                                        <th>nama_pemegang_hak</th>
                                                        <th>tgl_berakhir_hak</th>
                                                        <th>to_expire</th>
                                                    </tr>
                                                </thead>
                                                {/* <tbody>
                                                {datatable.map((result) => {
                                                    return(
                                                    <tr>
                                                        <td>{result.nomor}</td>
                                                        <td>{result.jenis}</td>
                                                        <td>{(result.tgl_terbit).substring(0, 10)}</td>
                                                        <td>{(result.tgl_aktif).substring(0, 10)}</td>
                                                        <td>{(result.estimasi_tgl_expired).substring(0, 10)}</td>
                                                        <td>{this.differ(result.estimasi_tgl_expired)}</td>
                                                    </tr>
                                                    )
                                                })}
                                                </tbody> */}
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <Sikil />
                    </div>
                </div>

                <a class="scroll-to-top rounded" href="#page-top">
                    <i class="fas fa-angle-up"></i>
                </a>
                <Bawah />
            </body>
        </html>
        );
    }
}

const omeH = Fuck(Home)
export default omeH;